import { useState, useEffect } from 'react'
import { getHandSelectedProduct } from '../../services/staticContentServices'
import { getContentProductInfo } from '../../services/visitorServices';
import { Typography, useMediaQuery } from '@material-ui/core';
import { isNotEmptyObject } from '../../aux/aux';
import ContentFixedSizeSliderItem from '../ContentFixedSizeSliderItem/ContentFixedSizeSliderItem'
import Carrousel from '../Carrousel/Carrousel';
import SliderCustom from '../SliderCustom/SliderCustom';
import './recommendedContentSection.css'
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import { useCountryAndCurrencyCtx } from '../../customHooks/useCountryAndCurrencyCtx';

const RecommendedContentSection = ({ 
    product=null, 
    selectedContentArray=null, 
    title=undefined, 
    subtitle=undefined, 
    onContentClick=()=>{}, 
    noVerticalPadding=false,
    wrapperProps={},
}) => {
    const localeCtx = useLocaleCtx()
    const countryAndCurrencyCtx = useCountryAndCurrencyCtx();
    const matchMinWidth980Px = useMediaQuery('(min-width:980px)');
    const matchMobile = useMediaQuery('(max-width:680px)');
    const [recommendedContentArray, setRecommendedContentArray] = useState(selectedContentArray);
    const [recommendedContentInfoMap, setRecommendedContentInfoMap] = useState(null);
    const maxSlidesWithoutSlider = matchMinWidth980Px ? 3 : matchMobile ? 1 : 2;
    const styles = {
        title: {
            textAlign: 'center',
            fontWeight: 'bold'
        },
        subtitle: {
            fontWeight: 'bold'
        }
    }

    const getNumSlides = () => {
        let numSlides;
        if(recommendedContentInfoMap != null){
            numSlides = Object.values(recommendedContentInfoMap).length;
        }else if(recommendedContentArray != null){
            numSlides = recommendedContentArray.length;
        }else{
            numSlides = 0;
        }
        return numSlides
    }

    const getRecommendedContent = async ( product, signal=null ) => {
        try{
            const opt = { 
                localeId: localeCtx.localeId,
                productTypeName: 'content',
                filteredBy:['accessible']
            };
            const res = await getHandSelectedProduct(opt, 0, signal);
            if(!signal.aborted){
                let filteredSelectedContentArray = []
                if(product){
                    filteredSelectedContentArray = res.data.reduce((contentArray, selectedProduct) => {
                        if(selectedProduct.productId !== product.id ){
                            contentArray.push(selectedProduct);
                        }
                        return contentArray
                    } ,[]);
                }
                setRecommendedContentArray(filteredSelectedContentArray);
            }
        }catch(error){
            // silencing error
        }
    }

    const getRecommendedContentInfo = async (abortSignal) => {
        try{
            const contentProductIdArray = recommendedContentArray.map( content => { return content.productId });
            const opt = {
                localeId: localeCtx.localeId, 
                currencyId: countryAndCurrencyCtx.currencyId,
                countryCode: countryAndCurrencyCtx.countryCode,
                stateCode: countryAndCurrencyCtx.stateCode,
                exp:['images'],
                asMap:true,
                imageFilter: ['imageTypeName = background', 'aspectRatio < 1']
            }
            const res = await getContentProductInfo(contentProductIdArray, opt, 0, abortSignal);
            const {
                contentProductInfoCollection,
                retrievalDetails
            } = res.data;
            if(!abortSignal.aborted){
                setRecommendedContentInfoMap(contentProductInfoCollection)
            }
        }catch(error){
            // silencing error
        }
    }

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        initialSlide: 0,
        //prevent from slide funtion to stop if slidesToShow = totalSlides = 4
        slidesToShow: 3.99,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1120,
                settings: {
                    //prevent from slide funtion to stop if slidesToShow = totalSlides = 3
                    slidesToShow: 3.99,
                }
            },
            {
                breakpoint: 980,
                settings: {
                    //prevent from slide funtion to stop if slidesToShow = totalSlides = 3
                    slidesToShow: 2.99,
                }
            },
            {
                breakpoint: 680,
                settings: {
                    //prevent from slide funtion to stop if slidesToShow = totalSlides = 2
                    slidesToShow: 1.99,
                }
            },
            {
                breakpoint: 340,
                settings:{
                    slidesToShow: 1,
                }
            }
        ]
    }

    useEffect(() => {
        if(!(selectedContentArray instanceof Array)){
            const abortController = new AbortController();
            getRecommendedContent(product, abortController.signal);
            return(() => {
                abortController.abort();
            })
        }
    },[product]);

    useEffect(() => {
        if(recommendedContentArray instanceof Array && recommendedContentArray.length > 0){
            const abortController = new AbortController();
            getRecommendedContentInfo(abortController.signal);
            return(() => {
                abortController.abort();
            })
        }
    },[recommendedContentArray, localeCtx])

    return (
        recommendedContentArray instanceof Array && recommendedContentArray.length > 0 ?
            <div className='recommended-content-section-wrapper' style={styles.wrapper} {...wrapperProps} >
                <div className={`recommended-content-section-main-container${noVerticalPadding ? ' no-vertical-padding': ''}`}>
                    {title &&
                        <Typography 
                            variant={matchMobile ? 'h5' : 'h4'} 
                            style={styles.title}
                            className='recommended-content-section-title'
                        >
                            {title}
                        </Typography>
                    }
                    {subtitle &&
                        <Typography 
                            variant={matchMobile ? 'h6' : 'h5'} 
                            style={styles.subtitle}
                            className='recommended-content-section-subtitle'
                        >
                            {subtitle}
                        </Typography>
                    }
                    <div className='recommended-content-section-carrousel-container'>
                        <Carrousel
                            Slider={SliderCustom}
                            sliderProps={{ settings, className:'recommended-content-section-carrousel-slider-inner-container' }}
                            hideSlider={getNumSlides() <= maxSlidesWithoutSlider}
                            noSliderClassName='recommended-content-section-carrousel-no-slider-inner-container'
                        >
                            {recommendedContentArray.map((contentProduct, index) => {
                                const content = recommendedContentInfoMap == null ? {} : recommendedContentInfoMap[contentProduct.productId] != null ? recommendedContentInfoMap[contentProduct.productId] : null; 
                                if(isNotEmptyObject(content)){
                                    content.details['selected'] = contentProduct
                                }
                                return(
                                    content !== null ?  
                                            <div key={index} className='recommended-content-section-carrousel-item'>
                                                <svg>
                                                    <rect x="0" y="0" fill="none" width="100%" height="100%"/>
                                                </svg>
                                                <ContentFixedSizeSliderItem content={content} onClick={onContentClick}/>
                                            </div>
                                        :
                                            null
                                )
                            })} 
                        </Carrousel>
                    </div>
                </div>
            </div>
            :
            null
    )
}

export default RecommendedContentSection