import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { lengthInMinToDurationString } from '../../aux/contentProductHelpers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { downloadFileFromBlob, getFromSafeObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import SectionLessonItinerary from '../SectionLessonItinerary/SectionLessonItinerary';
import './singleContentItinerary.css'
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import { getFileFromCDN } from '../../services/contentServices';
import ImgDN from '../../cdn/imgDN';
import cloudinary from '../../cdn/providers/cloudinary';

const DEFAULT_ITINERARY_FEATURES={
    lengthInMin:undefined,
    numSection:undefined,
    numLesson:undefined
}
const DEFAULT_CONTENT_STRUCTURE={
    sectionIdsStructure:[], 
    sectionSummaryMap:{}, 
    lessonSummaryMap:{},
    lessonVersionMap:{},
}
const DEFAULT_ITINERARY_DOWNLOAD_FILE={
    fileURI:undefined,
    fileName:undefined,
    onErrorDownload:undefined
}
const SingleContentItinerary = ({ 
        title, 
        subtitle, 
        contentStructure=DEFAULT_CONTENT_STRUCTURE, 
        itineraryDownloadFile=DEFAULT_ITINERARY_DOWNLOAD_FILE,
        numDefaultSectionToShow= undefined, 
        numDefaultLessonToShow=undefined, 
        maxNumLessonToShow= undefined,
        noVerticalPadding=false
    }) => {
    const DELAY_SELECTED_SECTION_MS = 300;
    const { t } = useTranslation('common');
    const theme = useTheme();
    const assetProvider = new ImgDN(cloudinary);
    const matchDesktop = useMediaQuery('(min-width: 1120px)');
    const matchMobile = useMediaQuery('(max-width: 680px)');
    const [ computedItineraryLenghts, setComputedItineraryLengths ] = useState(null);
    const [ selectedSectionId, setSelectedSectionId ] = useState(null);
    const [ delayedSelectedSectionId, setDelayedSelectedSectionId ] = useState(null);
    const [ itineraryFileURL, setItineraryFileURL ] = useState(null) 
    const [ numUntoggledSection, setNumUntoggledSection ] = useState(null);
    const [ isShownAllSection, setIsShownAllSection ] = useState(false);
    const [ isDownloadLoading, setIsDownloadLoading ] = useState(false);
    const abortControllerRef = useRef(null);
    
    const styles = {
        title: {
            fontWeight: 'bold',
            textAlign: 'center'
        },
        subtitle: {
            fontWeight: 'bold'
        },
        dot: {
            width: `calc(${theme.typography.body2.fontSize} * 0.3)`,
            height: `calc(${theme.typography.body2.fontSize} * 0.3)`,
            backgroundColor: theme.palette.grey[400],
            borderRadius: '50%'
        },
        itineraryInNumb:{
            color: theme.palette.grey[400],
        },
        selectedSectionNum:{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        sectionNum:{
            border: `dashed 1px ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.common.white,
        },
        selectedSectionTitle:{
            fontWeight: 'bold'
        },
        sectionTitle:{
        },
        descriptionDashedLine:{
            borderRight: `dashed 1px ${theme.palette.grey[400]}`
        },
        showMoreContainer:{
            color: theme.palette.primary.main
        },
        showMoreIcon:{
            display: 'block',
            width: theme.typography.body1.fontSize,
            height: theme.typography.body1.fontSize,
            ...(isShownAllSection ? { transform: 'rotate(180deg)' } : {})
        },
        downloadItineraryContainer:{
            // backgroundImage: `linear-gradient(${theme.palette.surface.light[1]}, rgba(255,255,255,0) 50% )`
        }
    }
    const handleOnSelectSection = (e, sectionId) => {
        e.preventDefault();
        setSelectedSectionId(sectionId)
    }
    
    const handleOnShowMore = (e) => {
        e.preventDefault();
        setIsShownAllSection(prev => !prev);
    }

    const handleOnDownloadFile = async (e, signal=abortControllerRef.current.signal) => {
        try{
            setIsDownloadLoading(true);
            if(itineraryFileURL){
                const res = await getFileFromCDN(itineraryFileURL, 'application/pdf', 0, signal);
                const data = res.data;
                if(data instanceof Blob){
                    const fileName = itineraryDownloadFile.fileName || 'qxi_content_itinerary.pdf';
                    if(!signal.aborted){
                        downloadFileFromBlob(data, fileName);
                    }
                }
            }
        }catch(error){
            console.warn('Download file failed', error)
            const onDownloadError = itineraryDownloadFile.onErrorDownload;
            if(!signal.aborted && typeof onDownloadError === 'function'){
                onDownloadError(error);
            }
        }finally{
            if(!signal.aborted){
                setIsDownloadLoading(false);
            }
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort()
        })
    },[])

    useEffect(() => {
        if(itineraryDownloadFile?.fileURI){
            const itineraryFileURL = assetProvider.getAssetUrl(itineraryDownloadFile.fileURI);
            setItineraryFileURL(itineraryFileURL);
        }else{
            setItineraryFileURL(null);
        }
    },[itineraryDownloadFile])

    useEffect(() => {
        if((contentStructure?.sectionIdsStructure?.length || 0) > 0 && isNotEmptyObject(contentStructure.sectionSummaryMap)){
            const numSection = contentStructure.sectionIdsStructure.length;
            const [lengthInMin, numLesson] = contentStructure.sectionIdsStructure.reduce(([accLength, accuLesson], sectionId) => {
                let section = contentStructure.sectionSummaryMap[sectionId] || {}
                accuLesson += section.lessonIdsStructure?.length || 0;
                accLength += (section.lengthInMin || 0);
                return[accLength, accuLesson]
            },[0,0])
            setComputedItineraryLengths({numSection, numLesson, lengthInMin});
            setSelectedSectionId(contentStructure.sectionIdsStructure[0]);
            if(Number.isFinite(numDefaultSectionToShow)){
                const numUntoggledSection = contentStructure.sectionIdsStructure.length - numDefaultSectionToShow;
                setNumUntoggledSection(Math.max(numUntoggledSection, 0))
            }
        }else{
            setSelectedSectionId(null);
            setNumUntoggledSection(null);
            setComputedItineraryLengths(null);
        }
    },[contentStructure])

    useEffect(() => {
        if(!isShownAllSection){
            const selectedSectionOrderIndex = getFromSafeObject(contentStructure, `sectionSummaryMap.[${selectedSectionId}].sectionOrderIndex`);
            if(selectedSectionOrderIndex != null && selectedSectionOrderIndex >= numDefaultSectionToShow){
                setSelectedSectionId(contentStructure.sectionIdsStructure[0])
            }
        }
    }, [isShownAllSection])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDelayedSelectedSectionId(selectedSectionId);
        }, DELAY_SELECTED_SECTION_MS);
        return(() => {
            clearTimeout(timeoutId);
        })
    },[selectedSectionId])

    return (
        selectedSectionId != null && computedItineraryLenghts != null ?
                <div className='single-content-itinerary-wrapper'>
                    <div className={`single-content-itinerary-main-container${noVerticalPadding ? ' no-vertical-padding' : ''}`}>
                        {title &&
                            <div className='single-content-itinerary-title-container'>
                                <Typography variant={matchMobile ? 'h5':'h4'} style={styles.title} >
                                    {title}
                                </Typography>
                            </div>
                        }
                        {subtitle &&
                            <div className='single-content-itinerary-subtitle-container'>
                                <Typography variant={matchMobile ? 'h6':'h5'} style={styles.subtitle}>
                                    {subtitle}
                                </Typography>
                            </div>
                        }
                        <div className='single-content-itinerary-content-container'>
                            <div className='single-content-itinerary-content-in-numbers-container'>
                                <div>
                                    <Typography variant='body2' style={styles.itineraryInNumb}>
                                        {`${computedItineraryLenghts.numSection} ${t('section',{count: computedItineraryLenghts.numSection})}`}
                                    </Typography>
                                </div>
                                <div className='single-content-itinerary-content-in-numbers-dot' style={styles.dot}/>
                                    <div>
                                        <Typography variant='body2' style={styles.itineraryInNumb}>
                                            {`${computedItineraryLenghts.numLesson} ${t('lesson',{count: computedItineraryLenghts.numLesson})}`}
                                        </Typography>
                                    </div>
                                    <div className='single-content-itinerary-content-in-numbers-dot' style={styles.dot}/>
                                    <div>
                                        <Typography variant='body2' style={styles.itineraryInNumb}>
                                            {lengthInMinToDurationString(computedItineraryLenghts.lengthInMin, t("common:hourAbbr"), t("common:minuteAbbr"))}
                                        </Typography>
                                    </div>
                            </div>
                            <div className='single-content-itinerary-content-sections-and-lessons-container'>
                                <div className='single-content-itinerary-content-sections-container'>
                                    {contentStructure.sectionIdsStructure.map((sectionId, index) => {
                                        const section = contentStructure.sectionSummaryMap[sectionId];
                                        const {
                                            description={}
                                        } = section;
                                        const isSelected = sectionId === selectedSectionId
                                        let isToggled = true;
                                        if(Number.isFinite(numDefaultSectionToShow)){
                                            isToggled = numDefaultSectionToShow > index || isShownAllSection;
                                        }
                                        return(
                                            description.title && description.description ?
                                                    <div key={index} className={`single-content-itinerary-content-section-container${isToggled ? ' show' : ''}`}>
                                                        <div className='single-content-itinerary-content-section-title-and-num'>
                                                            <div className='single-content-itinerary-content-section-num-container'> 
                                                                <div 
                                                                    className='single-content-itinerary-content-section-num' 
                                                                    style={isSelected ? styles.selectedSectionNum : styles.sectionNum }
                                                                    onClick={(e) => handleOnSelectSection(e, sectionId)}
                                                                >
                                                                    <Typography variant='body2'>
                                                                        {index+1}
                                                                    </Typography>
                                                                </div>
                                                                <div className='single-content-itinerary-content-section-num-dashed-line' style={styles.descriptionDashedLine} />
                                                            </div>
                                                            <div 
                                                                className='single-content-itinerary-content-section-title' 
                                                                onClick={(e) => handleOnSelectSection(e, sectionId)}
                                                            >
                                                                <Typography variant="body1" style={isSelected ? styles.selectedSectionTitle : styles.sectionTitle }>
                                                                    {description.title}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <div className='single-content-itinerary-content-section-description-container'>
                                                            <div className='single-content-itinerary-content-section-description-dashed-line' style={styles.descriptionDashedLine} />
                                                            <div className={`single-content-itinerary-content-section-description${isSelected ? ' show' : ''}`}>
                                                                <Typography key={index} variant="body1" style={{whiteSpace:'pre-line'}} p>
                                                                    {description.description}
                                                                </Typography>
                                                                {delayedSelectedSectionId != null && !matchDesktop ? 
                                                                    <div className={`single-content-itinerary-content-lessons-container${isSelected ? ' show' : ''}`}>
                                                                        <SectionLessonItinerary 
                                                                            section={contentStructure.sectionSummaryMap[delayedSelectedSectionId]}
                                                                            lessonSummaryMap={contentStructure.lessonSummaryMap}
                                                                            lessonVersionMap={contentStructure.lessonVersionMap}
                                                                            numDefaultLessonToShow={numDefaultLessonToShow}
                                                                            maxNumLessonToShow={maxNumLessonToShow}
                                                                            mainContainerBackgroundColor={theme.palette.background.default}
                                                                        />
                                                                        {itineraryFileURL &&
                                                                            <div className='single-content-itinerary-content-download-itinerary-container' style={styles.downloadItineraryContainer}>
                                                                                <ButtonLoading
                                                                                    isLoading={isDownloadLoading}
                                                                                    label={t('downloadFullProgram')}
                                                                                    color={'primary'}
                                                                                    variant={'contained'}
                                                                                    size="large"
                                                                                    onClick={(e) => handleOnDownloadFile(e)}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                : 
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>  
                                                :
                                                null   
                                        )
                                    })}
                                    {numUntoggledSection ?
                                        <div className='single-content-itinerary-sections-show-more-wrapper'>
                                            <div 
                                                className='single-content-itinerary-sections-show-more-container' 
                                                style={styles.showMoreContainer}
                                                onClick={handleOnShowMore}
                                            >
                                                <div>
                                                    <Typography variant='body2'>
                                                        {textTransform('title', t(isShownAllSection ? 'showLess' : 'showMore',{ elemNum: numUntoggledSection, elem: t('section', {count: numUntoggledSection})}))}
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <ExpandMoreIcon style={styles.showMoreIcon}/>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                {delayedSelectedSectionId && matchDesktop &&
                                    <div className='single-content-itinerary-content-lessons-container'>
                                        <div className='single-content-itinerary-content-lessons-wrapper'>
                                            <SectionLessonItinerary
                                                section={contentStructure.sectionSummaryMap[delayedSelectedSectionId]}
                                                lessonSummaryMap={contentStructure.lessonSummaryMap}
                                                lessonVersionMap={contentStructure.lessonVersionMap}
                                                numDefaultLessonToShow={numDefaultLessonToShow}
                                                maxNumLessonToShow={maxNumLessonToShow}
                                                mainContainerBackgroundColor={theme.palette.background.default}
                                            />
                                            {itineraryFileURL &&
                                                <div className='single-content-itinerary-content-download-itinerary-container' style={styles.downloadItineraryContainer}>
                                                    <ButtonLoading
                                                        isLoading={isDownloadLoading}
                                                        label={t('downloadFullProgram')}
                                                        color={'primary'}
                                                        variant={'contained'}
                                                        size="large"
                                                        onClick={(e) => handleOnDownloadFile(e)}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            :
                null
    )
}

export default SingleContentItinerary