import { useEffect, useState } from 'react'
import {Alert} from '@mui/material';
import { Typography, hexToRgb } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import './sectionLessonItinerary.css'
import { useTranslation } from 'react-i18next'
// Img and Art
import ReadingLectureIcon from '../../img/reading-lecture-icon.svg';
import VideoLectureIcon from '../../img/video-lecture-icon.svg';
import DownloadLectureIcon from '../../img/download-lecture-icon.svg';
import LiveLectureIcon from '../../img/live-lecture-icon.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { lengthInMinToDurationString } from '../../aux/contentProductHelpers';
import { getFromSafeObject, isEmptyObject, isNotEmptyObject, rgbToRgbaOrUndefined, textTransform } from '../../aux/aux';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import PopUpModal from '../PopUpModal/PopUpModal';
import ShowMoreStringPopOver from '../ShowMoreStringPopOver/ShowMoreStringPopOver';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import { getContentProductOpenLesson } from '../../services/visitorServices';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { triggerEventWithData } from '../../aux/internalCommunicationHelpers';
import StyledTypography from '../StyledTypography/StyledTypography';

// Prevent from retriggering effect dependent on those
// variables when the are not initialized in the parent component and any other prop changes
// because the refenreced object is always the same
const DEFAULT_SECTION = {
    lessonIdsStructure:[],
    description:{},
    lengthInMin:undefined,
};
const DEFAULT_LESSON_SUMMARY_MAP = {};
const DEFAULT_LESSON_VERSION_MAP = {};
const SectionLessonItinerary = ({ 
        section=DEFAULT_SECTION, 
        lessonSummaryMap=DEFAULT_LESSON_SUMMARY_MAP, 
        lessonVersionMap=DEFAULT_LESSON_VERSION_MAP,
        className=undefined,
        numDefaultLessonToShow=undefined, 
        maxNumLessonToShow=undefined,
        mainContainerBackgroundColor='#fff'
    }) => {
    const { t } = useTranslation('common');
    const theme = useTheme();
    const localeCtx = useLocaleCtx();
    const [lessonVideoToPreview, setLessonVideoToPreview] = useState(null);
    const [showLessonVideoToPreviewVersion, setShowLessonVideoToPreviewVersion] = useState(false);
    const [lessonIdMapToPreview, setLessonIdMapToPreview] = useState({});
    const [lessonOpenDetailsCacheMap, setLessonOpenDetailsCacheMap] = useState({});
    const [numUntoggledLesson, setNumUntoggledLesson] = useState(null);
    const [isShownAllLesson, setIsShownAllLesson] = useState(false);
    const [computedSectionLengths, setComputedSectionLengths] = useState(null);
    const [videoPlayerIsReadyAndVisible, setVideoPlayerIsReadyAndVisible] = useState(false);
    const styles = {
        mainContainer:{
            backgroundColor:mainContainerBackgroundColor
        },
        dot: {
            width: `calc(${theme.typography.body2.fontSize} * 0.3)`,
            height: `calc(${theme.typography.body2.fontSize} * 0.3)`,
            backgroundColor: theme.palette.grey[400],
            borderRadius: '50%'
        },
        itineraryInNumb:{
            color: theme.palette.grey[400],
        },
        sectionInNumbContainer:{
            borderBottom: `solid 1px ${theme.palette.primary.main}`,
        },
        lessonContainerBlurredWrapper:{
            background: `linear-gradient(rgba(255,255,255,0), ${mainContainerBackgroundColor} 65%)`,
        },
        lessonContainer:{
            backgroundColor: theme.palette.surface.light[1],
            borderColor: theme.palette.primary.main,
        },
        lessonLength:{
            color: theme.palette.grey[400],
        },
        lessonTypeIconContainer:{
            height: `${theme.typography.body1.lineHeight}rem`,
        },
        lessonTypeIcon:{
            display: 'block',
            width: theme.typography.body1.fontSize,
            height: theme.typography.body1.fontSize,
            fill: theme.palette.primary.main
        },
        showMoreContainer:{
            color: theme.palette.primary.main
        },
        showMoreIcon:{
            display: 'block',
            width: theme.typography.body1.fontSize,
            height: theme.typography.body1.fontSize,
            ...(isShownAllLesson ? { transform: 'rotate(180deg)' } : {})
        },
        previewModal:{
            backgroundColor: 'unset', 
            boxShadow: 'unset',
            ...(lessonVideoToPreview ? {} : {width: 'fit-content'}),
        },
        previewClickableText:{
            color: theme.palette.primary.main,
        },
        previewModalCloseIcon:{
            fill: theme.palette.common.white
        }
    }
    const getLessonIconComponent = (lessonType) => {
        let Icon;
        switch(lessonType) {
            case "video":
                Icon = VideoLectureIcon;
                break;
            case "reading":
                Icon = ReadingLectureIcon
                break;
            case "download":
                Icon = DownloadLectureIcon
                break;
            case "live":
                Icon = LiveLectureIcon
                break;
            default:
                Icon = null
        }
        return Icon
    }

    const requestOpenLesson = async (productId, lessonId, localeId, lessonVersionId, signal) => {
        try{
            const lessonVersionDecisor = { lessonVersionId };
            const res = await getContentProductOpenLesson(productId, lessonId, localeId, lessonVersionDecisor, 0, signal);
            const {
                openLesson,
                retrievalDetails
            } = res.data;
            if(openLesson && !signal.aborted){
                setLessonOpenDetailsCacheMap(prev => {
                    if(prev[lessonId]){
                        if(!prev[lessonId][lessonVersionId]){
                            prev[lessonId][lessonVersionId] = openLesson;
                        }
                    }else{
                        prev[lessonId] = { [lessonVersionId]: openLesson };
                    }
                    return {...prev};
                })
            }
        }catch(error){
            // DOING : Notify the preview is not available
        }
    }

    const handleOnVideoPlayerStateUpdate = (playerState) => {
        setVideoPlayerIsReadyAndVisible(playerState.isReady && playerState.isVisible);
    }

    const handleClickOnClosePreviewLessonVideo = (e) => {
        setLessonIdMapToPreview({});
    }
    
    const handleClickOnPreviewLessonVideo = (e, lesson) => {
        e.preventDefault();
        setLessonIdMapToPreview({ lessonId: lesson.id, lessonVersionId: lesson.version.lessonVersionId })
    }

    const handleClickOnShowMore = (e) => {
        e.preventDefault();
        setIsShownAllLesson(prev => !prev);
    }

    useEffect(() => {
        const lessonIdsStructure = section?.lessonIdsStructure || [];
        if(lessonIdsStructure.length > 0 && isNotEmptyObject(lessonSummaryMap)){
            const numLesson = lessonIdsStructure.length;
            const lengthInMin = section?.lengthInMin || 0
            setComputedSectionLengths({numLesson, lengthInMin})
            if(Number.isFinite(numDefaultLessonToShow)){
                const numUntoggledLessons = lessonIdsStructure.length - numDefaultLessonToShow;
                setNumUntoggledLesson(Math.max(numUntoggledLessons, 0))
            }
        }else{
            setNumUntoggledLesson(null);
            setComputedSectionLengths(null)
        }
    }, [section, numDefaultLessonToShow])

    useEffect(() => {
        if(lessonIdMapToPreview.lessonId && lessonIdMapToPreview.lessonVersionId){
            const lessonId = lessonIdMapToPreview.lessonId;
            const lessonVersionId = lessonIdMapToPreview.lessonVersionId;
            if(!getFromSafeObject(lessonOpenDetailsCacheMap, `${lessonId}.${lessonVersionId}`)){
                const abortController = new AbortController();
                const lesson = lessonSummaryMap[lessonId][lessonVersionId]; 
                const localeId = getFromSafeObject(lesson, 'description.localeId');
                requestOpenLesson(section.contentProductId, lessonId, localeId, lessonVersionId, abortController.signal);
                return(() => {
                    abortController.abort();
                })
            }else{
                const lessonOpen = lessonOpenDetailsCacheMap[lessonId][lessonVersionId];
                if(lessonOpen.lessonTypeName === 'video'){
                    const lessonVideoIdsStructure = getFromSafeObject(lessonOpen, 'contentStructure.videoIdsStructure');
                    if(lessonVideoIdsStructure && lessonVideoIdsStructure[0]){
                        const videoDetails = getFromSafeObject(lessonOpen, `contentStructure.videoDetailsMap.${lessonVideoIdsStructure[0]}`);
                        const version = lessonOpen.version || {};
                        setLessonVideoToPreview({video: videoDetails, version})
                    }
                }
            }
        }else{
            setVideoPlayerIsReadyAndVisible(false);
            setLessonVideoToPreview(null);
        }
    }, [lessonIdMapToPreview, lessonOpenDetailsCacheMap])

    useEffect(() => {
        if(videoPlayerIsReadyAndVisible){
            const timeoutId = setTimeout(() => setShowLessonVideoToPreviewVersion(true), 500);
            return(() => clearTimeout(timeoutId));
        }
    }, [videoPlayerIsReadyAndVisible])

    useEffect(() => {
        if(showLessonVideoToPreviewVersion){
            const timeoutId = setTimeout(() => setShowLessonVideoToPreviewVersion(false), 5000);
            return(() => clearTimeout(timeoutId));
        }
    }, [showLessonVideoToPreviewVersion])

    return (
        computedSectionLengths != null ?
            <div className={`section-lesson-itinerary-main-container${className ? ` ${className}` : ''}`} style={styles.mainContainer}>
                {lessonIdMapToPreview.lessonId && lessonIdMapToPreview.lessonVersionId ?
                        <PopUpModal 
                            showModal={lessonIdMapToPreview.lessonId && lessonIdMapToPreview.lessonVersionId}
                            onClickClose={handleClickOnClosePreviewLessonVideo}
                            onOutsideClick={handleClickOnClosePreviewLessonVideo}
                            style={styles.previewModal}
                            closeIconStyle={styles.previewModalCloseIcon}
                            className='section-lesson-itinerary-pop-over-container'
                            isDialog={false}
                            closeIconClassName='section-lesson-itinerary-pop-over-close-icon'
                        >
                            <div className='section-lesson-itinerary-pop-over-video-container'>
                                {lessonVideoToPreview ?
                                    <>
                                        <VideoPlayer video={lessonVideoToPreview.video} config={{autoplay:true}} onPlayerStateUpdate={handleOnVideoPlayerStateUpdate} cookieFreeIfPossible={true} />
                                            <div className={`section-lesson-itinerary-system-version-display-alert-container${showLessonVideoToPreviewVersion ? ' shown' : ''}`}>
                                                <Alert severity='info'>
                                                    <StyledTypography variant="body2">
                                                        {t('systemRetrieval.shownVersion', { versionedSystemName: t('systemRetrieval.systemDeviceNameWithSoftwareName', { deviceName: lessonVideoToPreview.version.deviceName.toUpperCase(), softwareName: lessonVideoToPreview.version.softwareName.toUpperCase() })})}
                                                    </StyledTypography>
                                                </Alert>
                                            </div>
                                    </>
                                    :
                                    <LoadingComponent visibleElements='circle' />
                                }
                            </div>
                        </PopUpModal>
                    :
                        null
                }
                <div className='section-lesson-itinerary-section-title-and-numbers-container' style={styles.sectionInNumbContainer}>
                    <div className='section-lesson-itinerary-section-title'>
                        <Typography variant='body2' style={styles.itineraryInNumb}>
                            {getFromSafeObject(section,'description.title')}
                        </Typography>
                    </div>
                    <div className='section-lesson-itinerary-section-in-numbers-container'>
                        <div>
                            <Typography variant='body2' style={styles.itineraryInNumb}>
                                {`${computedSectionLengths.numLesson} ${t('lesson', { count: computedSectionLengths.numLesson})}`}
                            </Typography>
                        </div>
                        <div style={styles.dot}/>
                        <div>
                            <Typography variant='body2' style={styles.itineraryInNumb}>
                                {lengthInMinToDurationString(computedSectionLengths.lengthInMin, t('hourAbbr'), t('minuteAbbr'))}
                            </Typography>
                        </div>
                    </div>
                </div>
                {isNotEmptyObject(lessonSummaryMap) && isNotEmptyObject(lessonVersionMap) ?
                        <div className='section-lesson-itinerary-lessons-container'>
                            {section.lessonIdsStructure.map((lessonId, index) => {
                                const lessonVersionsMap = lessonSummaryMap[lessonId];
                                const lessonVersionId = lessonVersionMap[lessonId];
                                const lesson = lessonVersionsMap[lessonVersionId];
                                const Icon = getLessonIconComponent(lesson.lessonTypeName);
                                let isToggled = true;
                                let isHidden = false;
                                let isBlurred = false;
                                if(Number.isFinite(numDefaultLessonToShow)){
                                    isToggled = numDefaultLessonToShow > index || isShownAllLesson;
                                }
                                if(Number.isFinite(maxNumLessonToShow)){
                                    isHidden = maxNumLessonToShow < index;
                                    isBlurred = maxNumLessonToShow === index;
                                }
                                return(
                                    isHidden || !lesson || isEmptyObject(lesson) ? 
                                        null
                                    :
                                        <div key={index} className={`section-lesson-itinerary-lesson-container${isToggled ? ' show': ''}${isBlurred ? ' no-border' : ''}`} style={styles.lessonContainer}>
                                            {isBlurred ?
                                                <div className='section-lesson-itinerary-blurred-wrapper' style={styles.lessonContainerBlurredWrapper} />
                                                :
                                                null
                                            }
                                            <div className='section-lesson-itinerary-lesson-title-and-icon-container'>
                                                <div className='section-lesson-itinerary-lesson-icon-container' style={styles.lessonTypeIconContainer}>
                                                    <Icon style={styles.lessonTypeIcon} />
                                                </div>
                                                <div className='section-lesson-itinerary-lesson-title-container'>
                                                    <Typography variant='body1'>
                                                        {getFromSafeObject(lesson,'description.title')}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div className='section-lesson-itinerary-lesson-preview-and-lenght-container'>
                                                <div className='section-lesson-itinerary-lesson-preview-container'>
                                                    {lesson.isOpen &&
                                                        <Typography variant='body2' style={styles.previewClickableText} onClick={e => handleClickOnPreviewLessonVideo(e, lesson)}>
                                                            {textTransform('title', t('preview'))}
                                                        </Typography>
                                                    }
                                                </div>
                                                <div className='section-lesson-itinerary-lesson-length'>
                                                    <Typography variant='body2' style={styles.lessonLength} >
                                                        {lengthInMinToDurationString(lesson.lengthInMin, t('hourAbbr'), t('minuteAbbr'))}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                )
                            })}
                            {numUntoggledLesson ?
                                    <div className='section-lesson-itinerary-show-more-wrapper'>
                                        <div 
                                            className='section-lesson-itinerary-show-more-container' 
                                            style={styles.showMoreContainer}
                                            onClick={handleClickOnShowMore}
                                        >
                                            <div className='section-lesson-itinerary-show-more-label-container'>
                                                <Typography variant='body2'>
                                                    {textTransform('title', t(isShownAllLesson ? 'showLess' : 'showMore',{ elemNum: section.lessonIdsStructure.length - numDefaultLessonToShow, elem: t('lesson', {count: section.lessonIdsStructure.length - numDefaultLessonToShow})}))}
                                                </Typography>
                                            </div>
                                            <div className='section-lesson-itinerary-show-more-icon-container'>
                                                <ExpandMoreIcon style={styles.showMoreIcon} className='section-lesson-itinerary-show-more-icon'/>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                            }
                        </div>
                    :
                        null
                }
            </div>
        :
            null
    )
}

export default SectionLessonItinerary