/**
 * This module contains all the helper methods to services that allow the
 * user to request, update, delete or reset data from content product
 */

import { getFromSafeObject, isEmptyString, isNotEmptyObject, toMillisecondsFromTimescale, toTimescaleFromMilliseconds } from "./aux";

const DEVICE_SOFTWARE_REGEX_DIVIDER = '&'

const getSystemRequestParameter = ({ deviceName, softwareName }) => {
    const areStrings = typeof(deviceName) === 'string' && typeof(softwareName) === 'string';
    const noEmpty = !isEmptyString(deviceName) && !isEmptyString(softwareName);
    return areStrings && noEmpty ? `${deviceName}${DEVICE_SOFTWARE_REGEX_DIVIDER}${softwareName}` : ''
}

const getLessonVersionExpRequestParameter = ({ lessonVersionId, deviceName, softwareName }) => {
    let lessonVersionDecisorString = '';
    if(lessonVersionId){
        lessonVersionDecisorString += lessonVersionId;
    }else if(deviceName && softwareName){
        lessonVersionDecisorString += getSystemRequestParameter({ deviceName, softwareName })

    }
    return lessonVersionDecisorString;
}

const contentProfessorArrayToString = (professorArray) => {
    return professorArray.reduce((accuString, professor) => {
        if(accuString){
            accuString += ', '
        }
        accuString += professor.fullName
        return accuString
    }, '');
}

const getMainProfessorOrNull = (professorArray) => {
    return professorArray.find(professor => professor.isMain === true) || null
}

const contentLocaleArrayToString = (localeArray, maxNum=2, propLocalePath='details.language.description.name') => {
    let accuString = '';
    const iterations = Math.min(localeArray.length, maxNum);
    for(let i = 0; i < iterations; i++){
        const locale = localeArray[i];
        if(accuString){
            accuString += ', '
        }
        accuString += getFromSafeObject(locale, propLocalePath)
    }
    return accuString;
} 

/**
 * Note: If elem prop is not fond that element is discarded
 * @param {*} array 
 * @param {*} propPath 
 * @param {*} maxNum 
 * @param {*} divider 
 * @returns 
 */
const concatenatedArrayElemPropString = (array, propPath, maxNum=2, divider=', ') => {
    let concatenatedString = '';
    const iterations = Math.min(array.length, maxNum);
    for(let i = 0; i < iterations; i++){
        const elem = array[i];
        if(concatenatedString){
            concatenatedString += divider
        }
        const propValue = getFromSafeObject(elem, propPath);
        if(propValue){
            concatenatedString += propValue;
        }

    }
    return concatenatedString;
} 

const lengthInMinToDurationString = (lengthInMin, hourAbbr, minAbbr) => {
    let durationString;
    let length = parseInt(lengthInMin);
    if(length){
        const longerThanHour = length > 60;
        if(longerThanHour){
            length = toTimescaleFromMilliseconds('hour', toMillisecondsFromTimescale('min', length));
            length = Math.round(length * 10) / 10
            durationString = `${length} ${hourAbbr}`
        }else{
            durationString = `${length} ${minAbbr}`
        }
    }
    return durationString
}

const getLessonTypeCountMap = (lessonSummaryMap) => {
    return Object.values(lessonSummaryMap || {}).reduce(([lessonTypeMap,totalNum],lesson) => {
        let typeName = lesson.lessonTypeName;
        if(!lessonTypeMap.hasOwnProperty(typeName)){
            lessonTypeMap[typeName] = 1;
        }else{
            lessonTypeMap[typeName] += 1
        }
        return [lessonTypeMap, totalNum + 1]
    }, [{}, 0])
}

const sameSystems = (system1, system2) => {
    return isNotEmptyObject(system1) && isNotEmptyObject(system2) && (system1.deviceName === system2.deviceName && system1.deviceName) && (system1.softwareName === system2.softwareName && system1.softwareName)
}

export{
    getSystemRequestParameter,
    getLessonVersionExpRequestParameter,
    contentProfessorArrayToString,
    getMainProfessorOrNull,
    concatenatedArrayElemPropString,
    contentLocaleArrayToString,
    lengthInMinToDurationString,
    getLessonTypeCountMap,
    sameSystems
}