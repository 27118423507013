import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/styles'
import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import LandingTrendingContent from '../LandingTrendingContent/LandingTrendingContent'
import './landingOurContent.css'
import { getHandSelectedProduct } from '../../services/staticContentServices'
import FutureContentSection from '../FutureContentSection/FutureContentSection'
import { getReliableLocale } from '../../aux/sessionHelpers'
import RecommendedContentSection from '../RecommendedContentSection/RecommendedContentSection'
import NestSections from '../NestSections/NestSections'
import { getFromSafeObject } from '../../aux/aux'
import { useLocaleCtx } from '../../customHooks/useLocaleCtx'

const LandingOurContent = ({ user }) => {
    const localeCtx = useLocaleCtx();
    const theme = useTheme();
    const history = useHistory();
    const matchMobile = useMediaQuery('(max-width:680px)');
    const { t } = useTranslation('landing', { keyPrefix: 'landingOurContent' }) 
    const [trendingContentArray, setTrendingContentArray] = useState(null);
    const [futureContentArray, setFutureContentArray] = useState(null);
    const styles = {
        wrapper: {
            backgroundColor: theme.palette.surface.light['1']
        },
        title: {
            fontWeight: 'bold'
        }
    }

    const getTrendingContentArray = async(abortSignal) => {
        try{
            const opt = { 
                localeId: localeCtx.localeId,
                productTypeName: 'content',
                filteredBy:['accessible']
            };
            const res = await getHandSelectedProduct( opt, 0, abortSignal);
            if(!abortSignal.aborted){
                setTrendingContentArray(res.data)
            }
        }catch(error){
            // silencing error
            setTrendingContentArray([])
        }
    }

    const getFutureContentArray = async(abortSignal) => {
        try{
            const opt = { 
                localeId: localeCtx.localeId,
                productTypeName: 'content',
                selectedProductTypeArray: ['comming'],
                filteredBy:['noAccessible','noReleased']
            };
            const res = await getHandSelectedProduct( opt, 0, abortSignal);
            if(!abortSignal.aborted){
                setFutureContentArray(res.data);
            }
        }catch(error){
            // silencing error
            setFutureContentArray([])
        }
    }

    const handleOnRecommendedContentProductClick = (contentProduct) => {
        const productTypeName = getFromSafeObject(contentProduct, 'details.product.productTypeName');
        const contentTypeName = contentProduct?.contentTypeName;
        const productName = getFromSafeObject(contentProduct, 'details.product.name');
        if(productTypeName && productName){
            history.push(`/${productTypeName}/${productName}`)
        }else{
        }
    }

    useEffect(() => {
        const abortController  = new AbortController();
        getTrendingContentArray(abortController.signal);
        getFutureContentArray(abortController.signal);
        return(() => {
            abortController.abort();
        })
    },[])

    return (
        trendingContentArray && futureContentArray && (trendingContentArray.length > 0 || futureContentArray.length > 0 ) ?
            <NestSections
                style={styles.wrapper}
                className={"landing-our-content-wrapper"}
            >
                <Typography 
                    variant={matchMobile ? 'h5' : 'h4'} 
                    style={styles.title}
                >
                    {t('ourContent')}
                </Typography>
                <RecommendedContentSection 
                    selectedContentArray={trendingContentArray}
                    onContentClick={handleOnRecommendedContentProductClick}
                    noVerticalPadding
                />
                <FutureContentSection 
                    user={user}
                    selectedContentArray={futureContentArray}
                    noVerticalPadding 
                />
            </NestSections>
        :
            null
    )
}

export default LandingOurContent