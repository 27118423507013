import { useTheme } from '@material-ui/styles';
import { Skeleton } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
// import ReactPlayer from 'react-player/vimeo
import ReactPlayer from 'react-player/lazy';
import { getFromSafeObject, isNotEmptyObject, textTransform, toTimescaleFromMilliseconds } from '../../aux/aux';
import { round2dec, truncate2dec } from '../../aux/purchaseHelpers';
import VideoThumbnailPreview from '../VideoThumbnailPreview/VideoThumbnailPreview';
import {Alert} from '@mui/material';
import { useTranslation } from 'react-i18next';
import './videoPlayer.css';
import { Button } from '@material-ui/core';
import { useMemo } from 'react';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

/**
 * 
 * @param {*} param0 
 * @returns
 * Notes: 
 *  - If video.description.videoSourceName === 'youtube' user captions interactions are not either track or updated in 
 *  userVideoConfig. However, the player do its best effort to set the initial captions config.
 */
const DEFAULT_USER_VIDEO_CONFIG = {};
const DEFAULT_USER_VIDEO_PROGRESS = {};
const VideoPlayer = ({ videoId, video, userVideoConfig=DEFAULT_USER_VIDEO_CONFIG, userVideoProgress=DEFAULT_USER_VIDEO_PROGRESS, youtube={}, vimeo={}, onPlayerConfigUpdate=()=>{},
     onViewingUpdate=()=>{}, onPlayerStateUpdate=()=>{}, onCompletion=()=>{}, onEnded=()=>{}, config={}, thumbnailOpt={}, cookieFreeIfPossible=false 
}) => {
    const theme = useTheme();
    const DEFAULT_PLAYER_CONFIG = {
        widthString:'100%', 
        heightString: undefined, 
        controls:true, 
        autoplay:false,
        updatingThresholdSec: 1, 
        minimumViewing: 0.9
    }
    const MINIMUM_VIEWING = config.minimumViewing || DEFAULT_PLAYER_CONFIG.minimumViewing;
    const UPDATING_THRESHOLD_SEC = config.updatingThresholdSec || DEFAULT_PLAYER_CONFIG.updatingThresholdSec;
    const { t } = useTranslation('common', { keyPrefix: 'videoPlayer' })
    const [viewingState, setViewingState] = useState({
        isSyncWithUserProgress: isNotEmptyObject(userVideoProgress),
        currentSecond: userVideoProgress.currentSecond ? userVideoProgress.currentSecond : 0.0, 
        currentFraction: userVideoProgress.currentFraction ? userVideoProgress.currentFraction : 0.0, 
        viewedSeconds:  userVideoProgress.viewedSeconds ? userVideoProgress.viewedSeconds : 0.0,
        viewedFraction: userVideoProgress.viewedFraction ? userVideoProgress.viewedFraction : 0.0, // fraction
        playbackIntervalArray: [],
        milestoneReached: userVideoProgress.milestoneReached ? userVideoProgress.milestoneReached : false,
        completed: userVideoProgress.completed ? userVideoProgress.completed : false,
    })
    const [playerbackRateListenerState, setPlayerbackRateListenerState] = useState(null);
    const [playerCaptionUpdateListenerState, setPlayerCaptionUpdateListenerState] = useState(null);
    const [playerState, setPlayerState] = useState({});
    const [updatingCounter, setUpdatingCounter] = useState(0);
    const [videoReachEnd, setVideoReachEnd] = useState(false);
    const playerRef = useRef(null);
    const isPlayerStateUpdatedFromOutsideRef = useRef(false);
    const abortControllerRef = useRef(null);
    const playerContainerSize = {
        // Define video player dimensions
        width: config.widthString ||  DEFAULT_PLAYER_CONFIG.widthString,
        height: config.heightString || DEFAULT_PLAYER_CONFIG.heightString,
    }
    const playerConfig = {
        // video size is defined by the player container size
        controls: config.controls || DEFAULT_PLAYER_CONFIG.controls,
        autoplay: config.autoplay || DEFAULT_PLAYER_CONFIG.autoplay,
        url: playerState.url,
    }

    const styles = {
        mainContainer:{
            width: playerContainerSize.width,
            ...(playerContainerSize.height ? {height: playerContainerSize.height} : {}),
        },
        videoWrapper: {
            position: 'relative',
            // Define video frame dimensions
            width: '100%', //playerConfig.width,
            ...(playerContainerSize.height ? 
                    {
                        height: '100%'
                    } 
                : 
                    {
                        paddingTop: '56.25%' 
                    }
            ),
            // comment it if height is defined. Player ratio: 100 / (1280 / 720)
        }
    }

    useEffect(()=>{
        if(video){
            setVideoReachEnd(false);
            const getVideoUrl = (sourceName, videoId) => {
                const setCookieFreeURL = (sourceName, url) => {
                    let resultUrl;
                    switch(sourceName){
                        case 'youtube':
                            resultUrl = url.replace('youtube.com', 'youtube-nocookie.com');
                            break;
                        case 'vimeo':
                            //  this is set directly in the component props
                            // resultUrl = `${url}?dnt=1`;
                        default:
                            resultUrl = url;
                    }
                    return resultUrl;
                }
                let url = undefined;
                if(sourceName && videoId){
                    const sourceVideoBaseUrl = {
                        vimeo: 'https://vimeo.com',
                        youtube: 'https://www.youtube.com/embed'
                    }
                    const baseUrl = sourceVideoBaseUrl[sourceName];
                    url = `${baseUrl}/${videoId}`;
                    url = cookieFreeIfPossible ? setCookieFreeURL(sourceName, url) : url;
                }
                return url;
            }
            const url = getVideoUrl(getFromSafeObject(video,'description.videoSourceName'), getFromSafeObject(video,'description.videoSourceId'));
            setPlayerState(prev => {
                if(url !== prev.url){
                    return {
                        isReady: prev.isReady || false,
                        isVisible: false,
                        playing: false || playerConfig.autoplay,
                        // duration: 0,
                        isNoURLError: !url,
                        isError:false,
                        url: url
                    }
                }else{
                    return prev
                }
            })
        }
    },[video, cookieFreeIfPossible])

    useEffect(()=>{
        if(userVideoProgress && !viewingState.isSyncWithUserProgress){
            setViewingState(prev => ({
                ...prev,
                isSyncWithUserProgress: true,
                currentSecond: userVideoProgress.currentSecond ? userVideoProgress.currentSecond : prev.currentSecond, 
                currentFraction: userVideoProgress.currentFraction ? userVideoProgress.currentFraction : prev.currentFraction, 
                viewedSeconds:  userVideoProgress.viewedSeconds ? userVideoProgress.viewedSeconds : prev.viewedSeconds,
                viewedFraction: userVideoProgress.viewedFraction ? userVideoProgress.viewedFraction : prev.viewedFraction, // fraction
                milestoneReached: userVideoProgress.milestoneReached ? userVideoProgress.milestoneReached : prev.milestoneReached,
                completed: userVideoProgress.completed ? userVideoProgress.completed : prev.completed,
            }))
        }
    },[userVideoProgress])

    // const needsUserVideoConfigUpdate = (userVideoConfig, playerState) => {
    //     try{
    //         return userVideoConfig.playbackRate !== playerState.playbackRate 
    //         || userVideoConfig.captionsEnabled !== playerState.captionsEnabled
    //         || getFromSafeObject(userVideoConfig,'userVideoConfig.details.captionsLocale.localeCode') !== playerState.captionsLocaleCode
    //     }catch(err){
    //         console.log(err)
    //     }
    // }

    useEffect(() => {
        if(userVideoConfig && playerState.isReady && playerState.isVisible){
            const newPlayerState = {
                // Currently is not kept track of playbackRate in userVideoConfig
                playbackRate: userVideoConfig.playbackRate || playerState.playbackRate || 1.0, 
                captionsEnabled: userVideoConfig.captionsEnabled ? userVideoConfig.captionsEnabled : false,
                captionsLocaleCode: userVideoConfig.captionsLocaleId != null ? userVideoConfig.details.captionsLocale.localeCode : '',
            }
            // if(playerRef.current){
                const player = playerRef.current.getInternalPlayer(); // get embeded Player SDK
                setPlayerCaptions(video.description.videoSourceName, player, newPlayerState.captionsEnabled, newPlayerState.captionsLocaleCode)
            // }
            setPlayerState( playerState => ({
                ...playerState,
                ...newPlayerState
            }));
        }
    }, [userVideoConfig, playerState.isReady, playerState.isVisible])

    const addPlayingInterval = (timestamp, playbackRate) => {
        setViewingState((viewingState) => { return {...viewingState, playbackIntervalArray: [...viewingState.playbackIntervalArray, {startTimestamp:timestamp, playbackRate: playbackRate}]}})
    }

    const updateViewingTime = (stopTimestamp, playedSeconds) => {
        const duration = playerRef.current.getDuration();
        let totalScaledViewedSeconds = 0.0
        let totalScaledViewing = 0.0
        let previousInterval = null;
        const playbackIntervalArray = [...viewingState.playbackIntervalArray, {startTimestamp:stopTimestamp, playbackRate:playerState.playbackRate}];
        for (let interval of playbackIntervalArray){
            if(previousInterval){
                let viewedSeconds =  toTimescaleFromMilliseconds('sec', interval.startTimestamp - previousInterval.startTimestamp);
                let scaledViewedSeconds = viewedSeconds * previousInterval.playbackRate;
                let scaledViewing = scaledViewedSeconds / duration;
                totalScaledViewedSeconds += scaledViewedSeconds;
                totalScaledViewing += scaledViewing;
            }
            previousInterval = interval;
        }
        totalScaledViewedSeconds +=  viewingState.viewedSeconds;
        totalScaledViewing += viewingState.viewedFraction;
        const viewedSeconds = truncate2dec(Math.min(totalScaledViewedSeconds, duration));
        const viewedFraction = truncate2dec(Math.min(totalScaledViewing, 1.0));
        const playedFraction = playedSeconds / duration;
        const currentSecond = truncate2dec(Math.min(playedSeconds, duration));
        const currentFraction = truncate2dec(Math.min(playedFraction, 1.0));

        setViewingState((viewingState) => { 
            return {
                 ...viewingState, 
                 playbackIntervalArray:[], 
                 currentSecond,
                 currentFraction, 
                 viewedSeconds,
                 viewedFraction 
                }
        })
    }

    const handleOnViewingUpdate = (signal=abortControllerRef.current.signal) => {
        const now = new Date();
        const {
            playbackIntervalArray,
            ...updatedValues
        } = viewingState
        if(userVideoProgress.completionDate == null && updatedValues.completed){
            updatedValues['completionDate'] = now;
        }
        updatedValues['lastUpdateDate'] = now;
        onViewingUpdate(videoId, updatedValues, signal);
    }

    const handleOnPlayerConfigUpdate = async (updatedValues, signal=abortControllerRef.current.signal) => {
        try{
            if(updatedValues.hasOwnProperty('captionsLocaleCode')){
                // Check there is a valid captionLocale
                updatedValues.captionsLocaleCode = updatedValues.captionsLocaleCode || null
            }
            await onPlayerConfigUpdate(videoId, updatedValues, signal);
        }catch(error){
            setPlayerState(state => ({...state, isError:true, errorMsg:t('errorUpdatingYourUserWithTheCurrentSetting')}))
        }
    }

    const handleOnCompletion = () => {
        setViewingState((viewingState) => { return {...viewingState, completed: true }});
        onCompletion();
    }

    /**
     * 
     * @param {object} state { playedSeconds:float, played:float, loadedSeconds:float, loaded: float} 
     *  played and loaded represent fractions of the current timeline
     */
    const handleOnProgress = (state) => {
        if(state.played >= MINIMUM_VIEWING && !viewingState.milestoneReached){
            setViewingState((viewingState) => { return {...viewingState, milestoneReached: true }});
        }
        if(state.played > 0 && playerState.playing){
            if(updatingCounter + 1 >= UPDATING_THRESHOLD_SEC || state.played >= 1.0){
                const timestamp = new Date().getTime();
                updateViewingTime(timestamp, state.playedSeconds);
                addPlayingInterval(timestamp, playerState.playbackRate);
                setUpdatingCounter(0);
            }else{
                setUpdatingCounter(updatingCounter => updatingCounter + 1);
            }
            // let currentSecond = round2dec(Math.min(state.playedSeconds, playerState.duration));
            // setViewingState(viewingState => { return {...viewingState, currentSecond }});
        }
    }

    // const handleOnDuration = (duration) => {
    //     setPlayerState((playerState) => { return {...playerState, duration}});
    // }

    const handleOnPlay = () => {
        // isVisible forces the player to be visible when the video ends in fullscreen
        // in that case the user cannot click on the thumbnail to start/replay the video
        setPlayerState((playerState) => { return {...playerState, playing:true, isVisible:true}});
        addPlayingInterval(new Date().getTime(), playerState.playbackRate);
        setUpdatingCounter(0);
    }

    const handleOnPause = (state) => {
        const sourceName = video.description.videoSourceName;
        let seconds;
        if(sourceName === 'vimeo'){
            seconds = state.seconds;
        }else if(sourceName === 'youtube'){
            // state prop is not received
            const player = playerRef.current.getInternalPlayer();
            seconds = player.getCurrentTime();
        }
        setPlayerState((playerState) => { return {...playerState, playing:false}});
        updateViewingTime(new Date().getTime(), seconds);
        setUpdatingCounter(0);
    }

    const handleOnPlaybackRateChange = (state) => {
        const sourceName = video.description.videoSourceName;
        let playbackRate;
        if(sourceName === 'vimeo'){
            playbackRate = state.playbackRate
        }else if(sourceName === 'youtube'){
            playbackRate = state.data
        }
        setPlayerbackRateListenerState(playbackRate);
    }

    const handleOnTextTrackChange = ( e ) => {
        const { kind, label, language } = e;
        const captionsLocaleCode = language != null ? language : '';
        const captionsEnabled = language != null;
        setPlayerCaptionUpdateListenerState({ captionsEnabled, captionsLocaleCode })
    }

    const handleOnYoutubeApiChange = ( e ) => {
        const sourceName = video.description.videoSourceName;
        if(sourceName === 'youtube'){
            // This player instance exposes its avilable options such as the captions module
            const player = e.target
            const availableModules = player.getOptions();
            if(availableModules.includes('captions')){
                setPlayerCaptions(sourceName, player, player.captionsEnabled, playerState.captionsLocaleCode)
            }
        }
    }

    const setPlayerCaptions = async (sourceName, player, captionsEnabled, localeCode=null) => {
        if(sourceName === 'vimeo'){
            try{
                if(captionsEnabled && localeCode){
                    await player.enableTextTrack(localeCode);
                }else{
                    await player.disableTextTrack();
                }
            }catch(error){
                console.log(error)
                switch(error.name){
                    case 'invalidTrackLanguageError':
                        break;
                    case 'invalidTrackError':
                        // There is no such text track
                        break;
                    default:
                        // some error occurred
                };
            }
        }else if(sourceName === 'youtube'){
            let trackToSet = {};
            if(captionsEnabled && localeCode){
                const tracklist = player.getOption('captions', 'tracklist');
                const trackToSetList = tracklist.filter((track) => track.languageCode.startsWith(playerState.captionsLocaleCode));
                trackToSet = trackToSetList[0] || {};
            }
            player.setOption('captions', 'track', trackToSet);
        }
    }

    const setPlayerEventListener = (sourceName, player, event) => {
        if(player){
            if(sourceName === 'vimeo'){
                switch(event){
                    case 'playbackratechange':
                        player.off('playbackratechange'); // clear possible listerners
                        player.on('playbackratechange', handleOnPlaybackRateChange); // set listener
                        break;
                    case 'texttrackchange':
                        player.off('texttrackchange'); // clear possible listerners
                        player.on('texttrackchange', handleOnTextTrackChange); // set listener
                        break;
                }
            }else if(sourceName === 'youtube'){
                // https://developers.google.com/youtube/iframe_api_reference#Loading_a_Video_Player
                switch(event){
                    case 'playbackratechange':
                        player.removeEventListener('onPlaybackRateChange', handleOnPlaybackRateChange);
                        player.addEventListener('onPlaybackRateChange', handleOnPlaybackRateChange);
                        break;
                    case 'onapichange':
                        // Info about captions API exposure: https://terrillthompson.com/648
                        player.removeEventListener('onApiChange', handleOnYoutubeApiChange);
                        player.addEventListener('onApiChange', handleOnYoutubeApiChange);
                        break;
                }
            }
        }
    }

    const handleOnReady = async () => {
        const sourceName = video.description.videoSourceName;
        playerRef.current.seekTo(viewingState.currentSecond,'seconds');
        const player = playerRef.current.getInternalPlayer(); // get embeded Player SDK
        // https://developers.google.com/youtube/iframe_api_reference#Loading_a_Video_Player
        // https://developer.vimeo.com/player/sdk/reference#about-player-events
        // Refactored at 10/10/23
        setPlayerEventListener(sourceName, player, 'playbackratechange');
        if(sourceName === 'vimeo'){
            setPlayerEventListener(sourceName, player, 'texttrackchange');
        }else{
            player.loadModule('captions'); // Forcing to load captions module on player
            setPlayerEventListener(sourceName, player, 'onapichange');
        }
        // player.off('playbackratechange'); // clear possible listerners
        // player.on('playbackratechange', handleOnPlaybackRateChange); // set listener
        // player.off('texttrackchange');
        // player.on('texttrackchange', handleOnTextTrackChange);
        setPlayerState(playerState => ({...playerState, isReady:true}));
    }

    const handleOnEnded = () => {
        setPlayerState(playerState => ({...playerState, playing:false, isVisible:false}));
        setVideoReachEnd(true);
        onEnded();
    }   

    const handleOnThumbnailPlayClick = () => {
        setPlayerState(playerState => ({...playerState, playing:true, isVisible:true}));
    }

    const handleOnThumbnailReplayClick = () => {
        const currentSecond = 0;
        playerRef.current.seekTo(currentSecond,'seconds');
        // setViewingState(viewingState => ({...viewingState, currentSecond}));
        handleOnThumbnailPlayClick();
    }

    const handleOnError = (error) => {
        const videoSourceName = getFromSafeObject(video,'description.videoSourceName')
        if(videoSourceName === 'vimeo' && error?.method === 'appendVideoMetadata') return // Error from VimeoPlayer 
        if(videoSourceName === 'youtube' && error == 5) return //Error from YouTube Player https://stackoverflow.com/questions/19497865/youtube-iframe-player-getting-error-code-5
        setPlayerState(playerState => ({...playerState, isError:true, errorMsg:t('errorOnPlayerVideoCanHaveErrorsWhilePlaying')}))
    }

    useEffect(() => {
        if(playerState.isError){
            const timeoutId = setTimeout(() => setPlayerState(prev => ({...prev, isError:false})), 3000)
            return(() => { clearTimeout(timeoutId)});
        }
    },[playerState.isError])

    useEffect(() => {
        if(playerbackRateListenerState){
            setPlayerState((playerState) => ({...playerState, playbackRate: playerbackRateListenerState}))
            if(playerState.isReady && playerState.isVisible){
                handleOnPlayerConfigUpdate({ playbackRate:playerbackRateListenerState });
            }
            if(playerState.playing){
                addPlayingInterval( new Date().getTime(), playerbackRateListenerState);
            }
        }
    }, [playerbackRateListenerState])

    useEffect(() => {
        if(playerCaptionUpdateListenerState){
            const newCaptionState = {captionsLocaleCode: playerCaptionUpdateListenerState.captionsLocaleCode, captionsEnabled: playerCaptionUpdateListenerState.captionsEnabled};
            setPlayerState(playerState => ({ ...playerState, ...newCaptionState }));
            if(playerState.isReady && playerState.isVisible){
                handleOnPlayerConfigUpdate(newCaptionState);
            }
        }
    },[playerCaptionUpdateListenerState])

    useEffect(() => {
        if(!viewingState.completed && viewingState.viewedFraction >= video.minimumViewedFraction && viewingState.milestoneReached){
            handleOnCompletion();
        }
    }, [viewingState.completed, viewingState.viewedFraction, viewingState.milestoneReached])

    useEffect(() => {
        if(playerState.isReady && playerState.isVisible){
            handleOnViewingUpdate()
        }
    }, [viewingState.currentSecond, viewingState.viewedSeconds, viewingState.milestoneReached, viewingState.completed]);

    useEffect(() => {
        if(playerState){
            onPlayerStateUpdate(playerState);
        }
    },[playerState])

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort();
        })
    },[])

    return (
        <div className='video-player-main-container' style={styles.mainContainer}>
            <div className='video-player-wrapper' style={styles.videoWrapper} >
                <div className={`video-player-background`} />
                <div className={`video-player-main ${(playerState.isReady && playerState.isVisible) ? 'visible' : '' }`}>
                        <ReactPlayer
                            ref={playerRef} 
                            {...playerConfig}
                            height={'100%'}
                            width={'100%'}
                            config={{
                                vimeo:{
                                    title: video.description.videoTitle,
                                    playerOptions:{
                                        color: theme.palette.primary.main.replace('#',''),
                                        playsinline:true, // Added 04/28 to allow inline play on small devices,
                                        dnt: cookieFreeIfPossible, // Cookie free configuration is set as query parameter. Default:false
                                        ...vimeo,
                                    },
                                },
                                youtube:{
                                    playerVars:{
                                        origin: window.origin,
                                        widget_referrer: window.origin,
                                        rel:0, 
                                        playsinline:1, // Added 04/28 to allow inline play on small devices
                                        // Cookie free is not set as query parameter is set has been set in the URL instead
                                        ...youtube,
                                    }
                                }
                            }}
                            onProgress={handleOnProgress} 
                            // onDuration={handleOnDuration} 
                            onPlay={handleOnPlay}
                            onPause={handleOnPause}
                            onReady={handleOnReady}
                            onEnded={handleOnEnded}
                            onError={handleOnError}
                            playing={playerState.playing}
                        />
                </div>
                <div className={`video-player-thumbnail ${(playerState.isReady && playerState.isVisible) ? 'hidden' : '' }`} >
                    {playerConfig.autoplay && !videoReachEnd ? 
                        <div className={`video-player-thumbnail-preview-loading`}>
                            {!playerState.isReady || true ? <LoadingComponent visibleElements='circle' /> : null }
                        </div>
                        :
                        <VideoThumbnailPreview 
                            isPlayerReady={playerState.isReady}
                            videoSourceName={video.description.videoSourceName} 
                            videoSourceId={video.description.videoSourceId} 
                            onPlayCallback={handleOnThumbnailPlayClick}
                            onReplayCallback={handleOnThumbnailReplayClick} 
                            showReplay={viewingState.currentFraction >= 1 || videoReachEnd}
                            imgOpt={thumbnailOpt}
                        />
                    }
                </div>
                {playerState.isNoURLError ?
                    <div className='video-player-error-container'>
                        <Alert severity='error'>
                            {textTransform('title', t('videoNoAvailable'))}
                        </Alert>
                    </div>
                    :
                    playerState.isError ? 
                        <div className='video-player-error-container'>
                            <Alert severity='warning'>
                                {textTransform('title', playerState.errorMsg)}
                            </Alert>
                        </div>
                        :
                            null
                }
            </div>
        </div>
    )
}

export default VideoPlayer