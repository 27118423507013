import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { isNotEmptyObject, textTransform } from '../../aux/aux';
import PlayIcon from '../../img/play-icon.svg'
import './featuredContent.css'
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import ImgLazyLoad from '../ImgLazyLoad/ImgLazyLoad';
import ImgDN from '../../cdn/imgDN';
import cloudinary from '../../cdn/providers/cloudinary';
import { getContentProductTrailer } from '../../services/visitorServices';
import PopUpModal from '../PopUpModal/PopUpModal';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
// Img and graphics
import CloseIcon from '../../img/close-fill.svg';
 
const FeaturedContent = ({ featuredContent, featuredContentTrailer=null, imgLazyLoadThreshold='100px' }) => {
    const theme = useTheme();
    const { t } = useTranslation('common');
    const matchDesktop = useMediaQuery('(min-width:1120px)');
    const matchMobile = useMediaQuery('(max-width:680px)');
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [featuredContentTrailerInfo, setFeaturedContentTrailerInfo] = useState(featuredContentTrailer);
    const [imgURL, setImgURL] = useState(null);
    const styles = {
        mainContainer:{
        },
        infoContainer:{
            backgroundImage: `linear-gradient(${matchDesktop ? '90deg' : '180deg'}, rgba(0,0,0,0), ${theme.palette.background.dark} ${matchDesktop ? '45%' : '90%'})`,
            borderRadius: matchDesktop ? '0px 5px 5px 0px' : '0px 0px 5px 5px'
        },
        chip:{
            backgroundColor: '#fff'
        },
        title:{
            color: theme.palette.text.contrast,
            textAlign: 'center',
            whiteSpace:'pre-line',
            lineHeight: '1'
        },
        divider:{
            backgroundColor: theme.palette.dividerOnDark,
        },
        description:{
            color: theme.palette.text.contrast,
            textAlign: 'center'
        },
        button:{
            borderColor: theme.palette.primary.lighter,
            color: theme.palette.primary.lighter,
        },
        icon:{
            fill: theme.palette.primary.lighter,
            height: '1em'
        },
        previewModal:{
            backgroundColor: 'unset', 
            boxShadow: 'unset',
            ...(featuredContentTrailerInfo ? {} : {width: 'fit-content'}),
        },
        previewModalCloseIcon:{
            fill: theme.palette.common.white
        },
        closeIcon: {
            height: '25px',
            fill: theme.palette.common.white,
        }
    }

    const handleOnPlay = (e) => {
        e.preventDefault();
        setIsVideoOpen(true);
    }

    const requestFeaturedContentPage = async(productIdOrName, localeId, abortSignal) => {
        try{
            const res = await getContentProductTrailer(productIdOrName, localeId, 0, abortSignal);
            if(!abortSignal.aborted){
                setFeaturedContentTrailerInfo(res.data);
            }
        }catch(error){
            // Loading component is shown
            // Error is silenced 
        }
    }

    useEffect(() => {
        if(isNotEmptyObject(featuredContent)){
            const abortController = new AbortController();
            requestFeaturedContentPage(featuredContent.productId, featuredContent.localeId, abortController.signal);
            return(() => abortController.abort())
        }
    },[featuredContent])

    useEffect(() => {
        if(isNotEmptyObject(featuredContentTrailerInfo)){
            let imgURL
            if(featuredContentTrailerInfo.imgURL){
                imgURL = featuredContentTrailerInfo.imgURL;
            }else if(featuredContentTrailerInfo.imgURI){
                const assetProvider = new ImgDN(cloudinary);
                imgURL = assetProvider.getAssetUrl(featuredContentTrailerInfo.imgURI);
            }else{
                imgURL = null;
            }
            setImgURL(imgURL)
        }else{
            setImgURL(null)
        }
    },[featuredContentTrailerInfo])

    return (
        isNotEmptyObject(featuredContentTrailerInfo) && featuredContentTrailerInfo.videoId && featuredContentTrailerInfo.videoSource ?
            <div className='featured-content-main-container' style={styles.mainContainer}>
                {isVideoOpen ? 
                    <PopUpModal 
                        showModal={isVideoOpen}
                        onClickClose={() => { setIsVideoOpen(false)}}
                        onOutsideClick={() => { setIsVideoOpen(false)}}
                        style={styles.previewModal}
                        closeIconStyle={styles.previewModalCloseIcon}
                        className='featured-content-pop-over-container'
                        isDialog={false}
                        closeIconClassName='featured-content-pop-over-close-icon'
                    >
                        <div className='featured-content-pop-over-video-container'>
                            {featuredContentTrailerInfo ?
                                <VideoPlayer video={{description:{ videoSourceId:featuredContentTrailerInfo.videoId, videoSourceName: featuredContentTrailerInfo.videoSource }}} config={{autoplay:true}} cookieFreeIfPossible={true} />
                                :
                                <LoadingComponent visibleElements='circle' />
                            }
                        </div>
                    </PopUpModal>
                    :
                    null
                }
                <ImgLazyLoad 
                    className='featured-content-img' 
                    alt={'content-featured cover img'}
                    src={imgURL}
                    threshold={imgLazyLoadThreshold}
                />
                <div className='featured-content-info-wrapper' style={styles.infoContainer}>
                    <Grid container direction='column' justifyContent='center' className='featured-content-info-container'>
                        {featuredContent?.selectedProductTypeName ? 
                            <Grid item align='center' className='featured-content-info-chip'>
                                <Chip 
                                    label={textTransform('title',t(featuredContent.selectedProductTypeName))} 
                                    style={styles.chip}
                                    size='small'
                                />
                            </Grid>
                            :
                            null
                        }
                        {featuredContentTrailerInfo.authorFullName ? 
                            <Grid item className='featured-content-info-title'>
                                <Typography variant={ matchMobile ? 'h4':'h3'} style={styles.title}>
                                    {featuredContentTrailerInfo.authorFullName}
                                </Typography>
                            </Grid>
                            :
                            null
                        }
                        {featuredContentTrailerInfo.authorFullName && featuredContentTrailerInfo.description ? 
                            <Grid item>
                                <div className='featured-content-info-divider' style={styles.divider}/>
                            </Grid>
                            :
                            null
                        }
                        {featuredContentTrailerInfo.description ? 
                            <Grid item className='featured-content-info-description'>
                                <Typography variant='body1' style={styles.description}>
                                    {featuredContentTrailerInfo.description}
                                </Typography>
                            </Grid>
                            :
                            null
                        }
                        <Grid item className='featured-content-info-button' align='center'>
                            <Button 
                                onClick={handleOnPlay}
                                variant='outlined' 
                                style={styles.button} 
                                startIcon={<PlayIcon 
                                style={styles.icon}/>}
                                size='large'
                            >
                                {t('watchTrailer')}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        :
            <div className='featured-content-loading-container'>
                <LoadingComponent visibleElements='circle'/>
            </div>
    )
}

export default FeaturedContent